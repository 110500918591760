.sContainer {
    position: relative;
}

.sContainer .mySwiper {
    width: 70%;
    position: unset;
}

@media screen and (max-width: 856px) {

    .sContainer .mySwiper {
        width: 90%;
    }
}