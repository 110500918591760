.swiper-button-prev {
    left: 6rem;
    position: absolute;
    color: black;
}

.swiper-button-next {
    position: absolute;
    right: 6rem;
    color: black;
}

@media screen and (max-width: 856px) {

    .swiper-button-prev,
    .swiper-button-next {
        display: none;
    }
}