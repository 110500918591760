@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
    --black: #343434;
    --bg: #F8E367;
    --shadow1: 0px 13px 48px -1px rgba(0, 0, 0, 0.25);
    --shadow2: 0px 13px 46px rgba(0, 0, 0, 0.13);
    --pink: #FE956F;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

body {
    background: var(--bg);
    transition: all 3ms ease;
    color: var(--black);
    scroll-behavior: smooth;
}

.App {
    display: flex;
    flex-direction: column;
}

::-webkit-scrollbar {
    height: 12px;
    width: 12px;
}

::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 1ex;
}

::-webkit-scrollbar-thumb {
    background: skyblue;
    border-radius: 1ex;
}

a {
    text-decoration: none;
    color: inherit
}